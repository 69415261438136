<template>
<div>
    <div class="pt-3">
        <columns>
            <column>
                <h5 class="title is-5 ml-3">Top 10 Teams This Month</h5>
            </column>
            <column class="is-narrow">
                <div class="buttons is-rounded has-addons">
                    <action-button @click="type = 'bar'" class="is-small is-rounded">
                        <icon icon="chart-simple"/>
                    </action-button>
                    <action-button @click="type = 'line'" class="is-small is-rounded">
                        <icon icon="chart-line"/>
                    </action-button>
                </div>
            </column>
        </columns>
    </div>
    <div id="customer-checklists-line-chart">
        <apexchart :type="type" height="300" :options="chartOptions" :series="series"></apexchart>
    </div>
</div>    
</template>
<script>
export default {

    props: {
      topTeams: {
        type: Array,
        default: () => ([])
      }
    },
  

    computed: {
        series() {
          return [{
            data: this.topTeams.map(team => Number(team.total_checklists))
          }]
        },
        chartOptions() {
          return {
            chart: {
              height: 350,
              type: 'bar',
              toolbar: {
                show: false
              }
            },
            colors: ['#2b70c0', '#E91E63', '#1de98a'],
            dataLabels: {
              enabled: true,
            },
            stroke: {
              curve: 'smooth',
              width: 2
            },
            grid: {
              borderColor: '#14141b',
            },
            markers: {
              size: 1
            },
            xaxis: {
              categories: this.topTeams.map(team => team.team_name), 
              labels: {
                style: {
                    colors: ['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff']
                }
              }
            },
            yaxis: {
                labels: {
                    style: {
                        colors: ['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff']
                    }
                }
            },
            legend: {
                show: true,
                position: 'bottom',
                labels: {
                    colors: ['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff']
                }
            },
            tooltip: {
                theme: 'dark',
            }
          }
        }
    }

}
</script>