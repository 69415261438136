<template>
<columns>
    <column>
        <columns>
            <column class="has-text-centered">
                <h1 class="title is-1">Finish Setting Up Your Account</h1>
                <p>There is not currently enough data available to build your dashboard.</p>
            </column>
        </columns>

        <columns class="is-centered">
            <column class="is-6">
                <div class="box" v-if="!status.equipment_classifications">
                    <h4 class="title is-4 has-text-primary">Classify Your Equipment</h4>

                    <div class="content">
                        <p>By classifying the equipment you work with you will be able to organise work and reporting
                            around the different types of equipment or assets your company deals with. Think of it as
                            categorising your equipment.
                        </p>
                    </div>
                    <router-link class="is-flex" :to="{
                        name: 'create-equipment-classification'
                    }">
                        <span>Classify Equipment</span>
                        <icon icon="arrow-right"/>
                    </router-link>
                </div>
                <div class="box" v-if="!status.checklist_templates">
                    <h4 class="title is-4 has-text-primary">Create a Checklist</h4>

                    <div class="content">
                        <p>All work is conducted by means of completing a checklist. Create your first checklist to start completing work orders.</p>
                    </div>
                    <router-link class="is-flex" :to="{
                        name: 'create-checklist-template'
                    }">
                        <span>Checklist Template</span>
                        <icon icon="arrow-right"/>
                    </router-link>
                </div>
                <div class="box" v-if="!status.customers">
                    <h4 class="title is-4 has-text-primary">Add a Customer</h4>

                    <div class="content">
                        <p>
                            Add your first customer to get started with issuing work orders.
                        </p>
                    </div>
                    <router-link class="is-flex" :to="{
                        name: 'create-customer'
                    }">
                        <span>Customer</span>
                        <icon icon="arrow-right"/>
                    </router-link>
                </div>
                <div class="box" v-if="!status.email_branding">
                    <h4 class="title is-4 has-text-primary">Upload your Logo</h4>
                
                    <div class="content">
                        <p>If you would like to personalise your email notifications and reporting we would recommend uploading your company branding.</p>
                    </div>
                    <router-link class="is-flex" :to="{
                        name: 'branding'
                    }">
                        <span>Upload Branding</span>
                        <icon icon="arrow-right" />
                    </router-link>
                </div>
                <div class="box has-text-centered" v-if="!status.equipment">
                    <div class="content">
                        <h4 class="title is-4 has-text-primary">Complete a Checklist</h4>
                        <p>1. Download Inspeckta from your preferred app store.</p>
                        <div class="is-flex is-justify-content-center mb-3">
                            <img src="/img/playstore.png" width="120" height="100" alt="inspeckta-logo">
                            <img src="/img/appstore.svg" width="95" height="100" alt="inspeckta-logo">
                        </div>
                        
                        <p>2. Create a Work Order</p>
                        <router-link 
                            class="button is-rounded" 
                            :to="{
                                name: 'create-work-order'
                            }">
                            <span>Work Order</span>
                            <icon icon="arrow-right" />
                        </router-link>
                    </div>
                </div>
            </column>
        </columns>
    </column>
</columns>    
</template>
<script>
export default {
    props: {
        status: {
            type: Object,
            default: () => ({})
        }
    }
}
</script>