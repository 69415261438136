<template>
  <div>
    <div id="customer-equipment-health">
      <apexchart
        type="donut"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>
<script>

export default {

  props: {
    faulty: Number,
    operational: Number,
  },

  data: () => ({
    chartOptions: {
      chart: {
        type: 'donut',
      },
      colors: ['#E91E63', '#1de98a'],
      labels: ['Faulty', 'Operational'],
      legend: {
        show: false,
      },
      stroke: {
        colors: ['#212127'],
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 150,
            },
          },
        },
      ],
    },
  }),

  computed: {
    series() {
      return [this.faulty, this.operational];
    },
  },
};
</script>