var render = function render(){var _vm=this,_c=_vm._self._c;return _c('columns',[_c('column',[_c('columns',[_c('column',{staticClass:"has-text-centered"},[_c('h1',{staticClass:"title is-1"},[_vm._v("Finish Setting Up Your Account")]),_c('p',[_vm._v("There is not currently enough data available to build your dashboard.")])])],1),_c('columns',{staticClass:"is-centered"},[_c('column',{staticClass:"is-6"},[(!_vm.status.equipment_classifications)?_c('div',{staticClass:"box"},[_c('h4',{staticClass:"title is-4 has-text-primary"},[_vm._v("Classify Your Equipment")]),_c('div',{staticClass:"content"},[_c('p',[_vm._v("By classifying the equipment you work with you will be able to organise work and reporting around the different types of equipment or assets your company deals with. Think of it as categorising your equipment. ")])]),_c('router-link',{staticClass:"is-flex",attrs:{"to":{
                        name: 'create-equipment-classification'
                    }}},[_c('span',[_vm._v("Classify Equipment")]),_c('icon',{attrs:{"icon":"arrow-right"}})],1)],1):_vm._e(),(!_vm.status.checklist_templates)?_c('div',{staticClass:"box"},[_c('h4',{staticClass:"title is-4 has-text-primary"},[_vm._v("Create a Checklist")]),_c('div',{staticClass:"content"},[_c('p',[_vm._v("All work is conducted by means of completing a checklist. Create your first checklist to start completing work orders.")])]),_c('router-link',{staticClass:"is-flex",attrs:{"to":{
                        name: 'create-checklist-template'
                    }}},[_c('span',[_vm._v("Checklist Template")]),_c('icon',{attrs:{"icon":"arrow-right"}})],1)],1):_vm._e(),(!_vm.status.customers)?_c('div',{staticClass:"box"},[_c('h4',{staticClass:"title is-4 has-text-primary"},[_vm._v("Add a Customer")]),_c('div',{staticClass:"content"},[_c('p',[_vm._v(" Add your first customer to get started with issuing work orders. ")])]),_c('router-link',{staticClass:"is-flex",attrs:{"to":{
                        name: 'create-customer'
                    }}},[_c('span',[_vm._v("Customer")]),_c('icon',{attrs:{"icon":"arrow-right"}})],1)],1):_vm._e(),(!_vm.status.email_branding)?_c('div',{staticClass:"box"},[_c('h4',{staticClass:"title is-4 has-text-primary"},[_vm._v("Upload your Logo")]),_c('div',{staticClass:"content"},[_c('p',[_vm._v("If you would like to personalise your email notifications and reporting we would recommend uploading your company branding.")])]),_c('router-link',{staticClass:"is-flex",attrs:{"to":{
                        name: 'branding'
                    }}},[_c('span',[_vm._v("Upload Branding")]),_c('icon',{attrs:{"icon":"arrow-right"}})],1)],1):_vm._e(),(!_vm.status.equipment)?_c('div',{staticClass:"box has-text-centered"},[_c('div',{staticClass:"content"},[_c('h4',{staticClass:"title is-4 has-text-primary"},[_vm._v("Complete a Checklist")]),_c('p',[_vm._v("1. Download Inspeckta from your preferred app store.")]),_c('div',{staticClass:"is-flex is-justify-content-center mb-3"},[_c('img',{attrs:{"src":"/img/playstore.png","width":"120","height":"100","alt":"inspeckta-logo"}}),_c('img',{attrs:{"src":"/img/appstore.svg","width":"95","height":"100","alt":"inspeckta-logo"}})]),_c('p',[_vm._v("2. Create a Work Order")]),_c('router-link',{staticClass:"button is-rounded",attrs:{"to":{
                                name: 'create-work-order'
                            }}},[_c('span',[_vm._v("Work Order")]),_c('icon',{attrs:{"icon":"arrow-right"}})],1)],1)]):_vm._e()])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }