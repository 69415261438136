<template>
<loader v-bind="{ loading }">
  <setup-status v-if="overview.recent_checklists.length === 0 && $root.isSuper()" :status="status"/>
  <overview v-else :overview="overview"/>
</loader>
</template>
<script>
import Overview from './Overview.vue'
import SetupStatus from './SetupStatus.vue'
import { get } from '@/api/request'

export default {

  components: {
    Overview,
    SetupStatus
  },

  data: () => ({
    loading: true,
    status: {
      has_equipment_classifications: false,
      has_branding: false,
    },
    overview: {
      total_equipment: 0,
      total_equipment_last_month: 0,
      faulty_equipment: 0,
      faulty_equipment_last_month: 0,
      overdue_checklists: 0,
      open_work_orders: 0,
      overdue_work_orders: 0,
      recent_checklists: [],
      checklist_graph: [],
      top_teams: [],
      equipment_summary: {
        operational: 0,
        faulty: 0,
      }
    }
  }),

  beforeCreate() {
    get('/v1/dashboard', ({data}) => {
      this.overview = data
      if(data.total_equipment === 0) {
        get('/v1/setup-status', ({data}) => {
          this.status = data
          this.loading = false
        }, () => this.loading = false)
      } else {
        this.loading = false
      }
    }, () => this.loading = false) 
    
  },

}
</script>